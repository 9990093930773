import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

const MissingDataSlugs = ({ data }) => {
  const {
    allInvalidSlugs: { nodes },
  } = data

  if (!nodes || nodes.length <= 0) {
    return <div>No missing slugs</div>
  }

  return (
    <div>
      <h1>Missing slugs:</h1>
      <pre>{JSON.stringify(nodes, null, 2)}</pre>
    </div>
  )
}

MissingDataSlugs.propTypes = {
  data: PropTypes.shape({
    allInvalidSlugs: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
        })
      ).isRequired,
    }),
  }),
}

export const query = graphql`
  query MissingDataSlugs {
    allInvalidSlugs(filter: { id: { ne: "PLACEHOLDER" } }) {
      nodes {
        slug
        type
      }
    }
  }
`

export default MissingDataSlugs
